import { jsx as _jsx } from "react/jsx-runtime";
import { FlagdWebProvider } from '@openfeature/flagd-web-provider';
import { OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk';
import { getConfig } from './common/config';
var FeatureProvider = function (_a) {
    var children = _a.children;
    var config = getConfig().featureProvider;
    OpenFeature.setProvider(new FlagdWebProvider(config));
    return _jsx(OpenFeatureProvider, { children: children });
};
export default FeatureProvider;

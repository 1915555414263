import '@fontsource/raleway'; // Defaults to weight 400.
import { grey, red, teal } from '@mui/material/colors';
export var Color;
(function (Color) {
    Color["Main"] = "#387A26";
    Color["Secondary"] = "#192A44";
    Color["White"] = "#FFFFFF";
    Color["Background"] = "#F8F8F8";
    Color["LightGray"] = "#B5B5B5";
    Color["Gray"] = "#D9D9D9";
    Color["Text"] = "#393939";
    Color["Error"] = "#ff0000";
    Color["OffWhite"] = "#F1F1F1";
    Color["Green"] = "#6FCA84";
    Color["PricerLogo"] = "#054816";
    Color["LightBorder"] = "#CECFCE";
})(Color || (Color = {}));
var darkThemeColors = {
    Main: grey[500],
    Secondary: teal[400],
    White: grey[900],
    Background: grey[800],
    LightGray: grey[600],
    Gray: grey[700],
    Text: grey[50],
    Error: red[500],
    OffWhite: grey[700],
    Green: teal[300],
    PricerLogo: grey[400],
    LightBorder: grey[600]
};
var lightThemeColors = {
    Main: '#387A26',
    Secondary: '#192A44',
    White: '#FFFFFF',
    Background: '#F8F8F8',
    LightGray: '#B5B5B5',
    Gray: '#D9D9D9',
    Text: '#393939',
    Error: '#ff0000',
    OffWhite: '#F1F1F1',
    Green: '#6FCA84',
    PricerLogo: '#054816',
    LightBorder: '#CECFCE'
};
export var getTheme = function (darkMode) {
    var colors = darkMode ? darkThemeColors : lightThemeColors;
    return {
        components: {
            // Name of the component
            MuiButton: {
                styleOverrides: {
                    // Name of the slot
                    contained: {
                        color: colors.White,
                        boxShadow: 'none'
                    },
                    outlined: {
                        color: colors.Main,
                        background: colors.White,
                        boxShadow: 'none',
                        '&:hover': {
                            //you want this to be the same as the backgroundColor above
                            background: colors.White,
                            border: 'none'
                        }
                    },
                    textPrimary: {},
                    containedPrimary: {
                        color: colors.White
                    },
                    root: {
                        textTransform: 'unset',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                        width: '144px',
                        height: '40px',
                        backgroundColor: colors.Main,
                        color: colors.White,
                        border: 0,
                        boxShadow: 'none',
                        '&:hover': {
                            //you want this to be the same as the backgroundColor above
                            backgroundColor: colors.Main,
                            border: 'none'
                        }
                    }
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        fontWeight: 500,
                        backgroundColor: colors.White,
                        fontSize: 'smaller'
                    },
                    secondary: {
                        fontSize: 'smaller'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-input:-webkit-autofill': {
                            WebkitBoxShadow: "0 0 0 100px ".concat(colors.OffWhite, " inset"),
                            WebkitTextFillColor: colors.Text
                        }
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        '&:focus': {
                            backgroundColor: colors.White
                        }
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    colorPrimary: {
                        border: 'solid 1px #3f64de',
                        backgroundColor: '#e8f2ff',
                        color: '#3f64de',
                        '&&:focus': {
                            backgroundColor: '#e8f2ff',
                            color: '#3f64de'
                        },
                        '&&:hover': {
                            backgroundColor: '#5d87bd',
                            color: colors.White
                        }
                    },
                    colorSecondary: {
                        border: "solid 1px ".concat(colors.Error),
                        backgroundColor: '#ffefef',
                        color: colors.Error,
                        '&&:focus': {
                            backgroundColor: '#ffefef',
                            color: colors.Error
                        },
                        '&&:hover': {
                            backgroundColor: '#e65555',
                            color: colors.White
                        }
                    }
                }
            }
        },
        palette: {
            background: {
                default: colors.Background
            },
            primary: {
                main: colors.Main
            },
            secondary: {
                main: colors.Secondary
            },
            error: {
                main: colors.Error
            },
            divider: colors.LightBorder
        },
        typography: {
            fontFamily: "\"Raleway\", \"Helvetica\", \"Arial\", \"sans-serif\"",
            h1: {
                fontSize: 48,
                fontWeight: 500,
                lineHeight: 1.17,
                color: colors.Text
            },
            h2: {
                fontSize: 24,
                lineHeight: 1.33,
                fontWeight: 'bold',
                color: colors.Text
            },
            h3: {
                fontSize: 18,
                lineHeight: 1.33,
                fontWeight: 'bold',
                color: colors.Text
            },
            subtitle1: {
                fontSize: 14,
                color: colors.Text,
                fontWeight: 'normal',
                lineHeight: 1.43,
                letterSpacing: '0.15px'
            },
            subtitle2: {
                fontSize: 14,
                color: colors.Text,
                fontWeight: 'bold',
                lineHeight: 1.43,
                letterSpacing: '0.15px'
            },
            overline: {
                fontSize: 14,
                color: 'rgba(0, 0, 0, 0.25)',
                fontWeight: 'normal',
                lineHeight: 1.43,
                letterSpacing: '0.15px'
            },
            caption: {
                fontSize: 12,
                fontWeight: 'normal',
                lineHeight: 2.58,
                color: 'rgba(0, 0, 0, 0.54)',
                letterSpacing: '1px'
            }
        }
    };
};

import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Application from './Application';
import { getConfig } from './common/config';
import FeatureProvider from './FeatureProvider';
import { store } from './redux/store';
import ThemeProvider from './ThemeProvider';
export var AppRoot = function () {
    return (_jsx(Auth0Provider, { domain: getConfig().auth0Domain, clientId: getConfig().auth0ClientId, authorizeTimeoutInSeconds: 5, httpTimeoutInSeconds: 5, authorizationParams: {
            audience: getConfig().auth0Audience,
            redirect_uri: window.location.origin
        }, children: _jsx(FeatureProvider, { children: _jsx(ThemeProvider, { children: _jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(Application, {}) }) }) }) }) }));
};

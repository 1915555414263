import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../common/hooks/authentication';
import { Color } from '../../theme';
import PricerLogo from './assets/pricer-logo.svg';
import PlazaUsersSettingsMenu from './settingsMenu/PlazaUsersSettingsMenu';
import SettingsMenu from './settingsMenu/SettingsMenu';
export default function TopMenu() {
    var navigate = useNavigate();
    var _a = useAuthentication(), parentTenant = _a.parentTenant, isPlazaUser = _a.isPlazaUser;
    var goHome = function () {
        navigate('/tenants/' + parentTenant);
    };
    return (_jsx(Box, { sx: { marginBottom: '65px' }, children: _jsx(AppBar, { elevation: 0, position: "fixed", sx: {
                borderBottom: "1px solid ".concat(Color.LightBorder),
                zIndex: function (theme) { return theme.zIndex.drawer + 1; },
                backgroundColor: Color.White
            }, children: _jsxs(Toolbar, { children: [_jsx(IconButton, { size: "large", edge: "start", "aria-label": "menu", sx: { mr: 2, color: Color.PricerLogo }, children: _jsx(MenuIcon, {}) }), _jsx("img", { src: PricerLogo, alt: "Pricer Logo", style: { cursor: 'pointer' }, onClick: goHome }), _jsx("div", { style: {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }, children: _jsx(Box, { sx: { display: 'flex', alignItems: 'center', minWidth: '120px' }, children: _jsx(Stack, { direction: "row", spacing: 2, children: isPlazaUser() ? _jsx(PlazaUsersSettingsMenu, {}) : _jsx(SettingsMenu, {}) }) }) })] }) }) }));
}

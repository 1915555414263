import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { getTheme } from './theme';
var FeatureProvider = function (_a) {
    var children = _a.children;
    var darkMode = useBooleanFlagValue('dark-mode', false);
    var theme = createTheme(getTheme(darkMode));
    return (_jsxs(ThemeProvider, { theme: responsiveFontSizes(theme), children: [_jsx(CssBaseline, {}), children] }));
};
export default FeatureProvider;
